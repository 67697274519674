import { lazy, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// import { element } from 'prop-types';
import SuperAdminLayout from '../layouts/super-admin/dashboard';
import AuthGuard from '../guards/AuthGuard';
import SuperAdminGuard from '../guards/SuperAdminGuard';
import { SUPER_ADMIN_DASHBOARD } from '../config';
import LoadingScreen from '../components/LoadingScreen';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/super-admin/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

const Dashboard = Loadable(lazy(() => import('../pages/users/super-admin/dashboard/Dashboard')));
const Roles = Loadable(lazy(() => import('../pages/user-management/Role/Roles')));
const RolePermission = Loadable(lazy(() => import('../pages/user-management/Role/RolePermission')));
const RoleOperation = Loadable(lazy(() => import('../pages/user-management/Role/RoleOperation')));
const Users = Loadable(lazy(() => import('../pages/users/super-admin/User/Users')));
const UserOperation = Loadable(lazy(() => import('../pages/users/super-admin/User/UserOperation')));
const UserAccount = Loadable(lazy(() => import('../pages/profile/UserAccount')));
const Languages = Loadable(lazy(() => import('../pages/users/super-admin/masters/Language/Languages')));
const LanguageOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Language/LanguageOperation')));
const Faqs = Loadable(lazy(() => import('../pages/users/super-admin/masters/FAQ/FAQs')));
const FaqOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/FAQ/FAQOperation')));
const NotificationTypeOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/NotificationType/NotificationTypeOperation')));
const NotificationTypes = Loadable(lazy(() => import('../pages/users/super-admin/masters/NotificationType/NotificationTypes')));
// const Categories = Loadable(lazy(() => import('../pages/users/super-admin/masters/Category/Categories')));
// const CategoryOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Category/CategoryOperation')));
const Videos = Loadable(lazy(() => import('../pages/users/super-admin/masters/Video/Videos')));
const VideoOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/Video/VideoOperation')));
const PageContent = Loadable(lazy(() => import('../pages/users/super-admin/masters/PageContent/PageContents')));
const PageContentOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/PageContent/PageContentOperation')));
const GlobalSettings = Loadable(lazy(() => import('../pages/users/super-admin/masters/GlobalSettings/GlobalSettings')));
const GlobalSettingsOperation = Loadable(lazy(() => import('../pages/users/super-admin/masters/GlobalSettings/GlobalSettingsOperation')));

const SuperAdminRoutes = {
    path: '/super-admin',
    element: (
        <AuthGuard>
            <SuperAdminGuard>
                <SuperAdminLayout />
            </SuperAdminGuard>
        </AuthGuard>
    ),
    children: [
        { element: <Navigate to={SUPER_ADMIN_DASHBOARD} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        {
            path: 'profile',
            children: [{ path: 'edit-profile', element: <UserAccount /> }],
        },
        {
            path: 'user-management',
            children: [
                { path: 'roles', element: <Roles /> },
                { path: 'role/:id/permission', element: <RolePermission /> },
                { path: 'role/add', element: <RoleOperation /> },
                { path: 'role/:id/edit', element: <RoleOperation /> },
                { path: 'role/:id/view', element: <RoleOperation /> },
                { path: 'users', element: <Users /> },
                { path: 'user/add', element: <UserOperation /> },
                { path: 'user/:id/edit', element: <UserOperation /> },
                { path: 'user/:id/view', element: <UserOperation /> },
                // { path: 'user-approval', element: <UserApproval /> },
                // { path: 'user-approval/:id/edit', element: <UserApprovalOperation /> },
            ],
        },
        {
            path: 'master',
            children: [
                { path: 'languages', element: <Languages /> },
                { path: 'language/add', element: <LanguageOperation /> },
                { path: 'language/:id/edit', element: <LanguageOperation /> },
                { path: 'language/:id/view', element: <LanguageOperation /> },
                { path: 'faqs', element: <Faqs /> },
                { path: 'faq/add', element: <FaqOperation /> },
                { path: 'faq/:id/edit', element: <FaqOperation /> },
                { path: 'faq/:id/view', element: <FaqOperation /> },
                // { path: 'categories', element: <Categories /> },
                // { path: 'categories/add', element: <CategoryOperation /> },
                // { path: 'categories/:id/edit', element: <CategoryOperation /> },
                // { path: 'categories/:id/view', element: <CategoryOperation /> },
                { path: 'videos', element: <Videos /> },
                { path: 'videos/add', element: <VideoOperation /> },
                { path: 'videos/:id/edit', element: <VideoOperation /> },    
                { path: 'videos/:id/view', element: <VideoOperation /> },
                { path: 'notification-types', element: <NotificationTypes /> },
                { path: 'notification-types/add', element: <NotificationTypeOperation /> },
                { path: 'notification-types/:id/edit', element: <NotificationTypeOperation /> },
                { path: 'notification-types/:id/view', element: <NotificationTypeOperation /> },

            ],
        },
        
        {
            path: 'page-content',
            children: [
                { path: '', element: <PageContent /> },
                { path: 'add', element: <PageContentOperation /> },
                { path: ':id/edit', element: <PageContentOperation /> },
                { path: ':id/view', element: <PageContentOperation /> },
            ],
        },
        {
            path: 'global-settings',
            children: [
                { path: '', element: <GlobalSettings /> },
                { path: 'add', element: <GlobalSettingsOperation /> },
                { path: ':id/edit', element: <GlobalSettingsOperation /> },
                { path: ':id/view', element: <GlobalSettingsOperation /> },
            ],
        },
    ],
};

export default SuperAdminRoutes;
